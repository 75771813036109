<template>
  <div class="bx-footer__abo-teaser bx-form bx-typo--form">
    <div class="bx-footer__abo-teaser-image">
      <img :src="coverUrl"
           loading="lazy"
           width="2"
           height="3"
           data-pin-nopin="true"
           alt="Cover der aktuellen Ausgabe von selbst ist der Mann"
           title="Cover der aktuellen Ausgabe von selbst ist der Mann">
    </div>
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        Aktuelle Ausgabe von selbst&nbsp;ist&nbsp;der&nbsp;Mann
      </span>
      <a target="_blank"
         rel="noopener"
         href="https://www.bauer-plus.de/selbst-ist-der-mann/">
        <button class="bx-form__button">
          abonnieren
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coverUrl: {
      type: String,
      default: null
    }
  }
}
</script>
