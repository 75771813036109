<template>
  <div class="bx-header__search bx-typo--header-search">
    <a aria-label="Suche öffnen"
       class="icon icon--header-search"
       tabindex="0"
       @click="toggleSearch()"
       @keyup.enter="toggleSearch()">
      <svg><use href="/images/icon_sprite.svg#search" /></svg>
    </a>
    <form class="bx-form bx-typo--form bx-form--search-header bx-typo--form-search-header"
          onsubmit="return false;"
          @submit="submitSearch()">
      <input id="bx-header-search-input"
             v-model="searchPhrase"
             type="search"
             class="bx-form__input"
             title="Suche"
             placeholder="selbst.de durchsuchen ...">
      <a aria-label="Suche schliessen"
         class="icon icon--header-close"
         tabindex="0"
         @click="toggleSearch()"
         @keyup.enter="toggleSearch()">
        <svg><use href="/images/icon_sprite.svg#close" /></svg>
      </a>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchPhrase: '',
      showSearch: false
    }
  },
  methods: {
    toggleSearch () {
      const body = document.getElementsByTagName('body')[0]
      this.showSearch = body.classList.toggle('bx-js-show-search')
      if (this.showSearch) {
        document.getElementById('bx-header-search-input').focus()
      }
    },
    submitSearch () {
      window.location.href = `/suche?query=${encodeURIComponent(document.getElementById('bx-header-search-input').value.trim())}`
    }
  }
}
</script>
