<template>
  <div>
    <a class="bx-header__logo bx-header__logo--selbst"
       title="https://www.selbst.de/"
       href="/">
      <img src="/images/selbst/selbst-logo.svg"
           alt="Selbst ist der Mann Logo"
           width="156"
           height="80">
    </a>
  </div>
</template>

<script>
export default {}
</script>
